import React, { useEffect, useState } from "react"

function ClientOnly({ children, ...delegated }) {
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vh",
          background:
            "linear-gradient(180deg, rgba(20,36,51,1) 0%, rgba(0,155,165,1) 100%)",
        }}
      ></div>
    )
  }
  return <div {...delegated}>{children}</div>
}

export default ClientOnly
