import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as styles from "./404.module.css"
import ClientOnly from "../components/clientOnly"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <div>
      <SEO title="404: Page Not Found" />
      <ClientOnly>
        <Layout location={location} title={siteTitle}>
          <div className={`section ${styles.container}`}>
            <div className={styles.gridItem} />
            <div className={`${styles.gridItem} ${styles.oneThird}`}>
              <h2>404: Page Not Found</h2>
              <p>
                We&#39;re sorry - we can&#39;t find the page you&#39;re looking
                for.
              </p>
            </div>
          </div>
        </Layout>
      </ClientOnly>
    </div>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
